
import React, { useState } from 'react';
import image from "./image/logoblackn.webp"
import { Link } from 'react-router-dom';
import logoaaopadhe from "./image/aaopadhe logo.webp"



const Navbar = ({uni}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`bg-white w-full`}>
    <div className="w-full flex justify-around px-4 sm:px-6 lg:px-8">
      <div className="flex w-[98%] justify-between h-16">
        <div className="flex w-full justify-between">
          {/* Logo Section */}
          <div className="flex-shrink-0 flex items-center">
            <Link to="/">
              <img className="block lg:hidden h-10 w-auto" src={image} alt="Logo" />
            </Link>
            <Link to="/">
              <img className="hidden lg:block h-[2.5rem] w-auto" src={image} alt="Logo" />
            </Link>
          </div>
  
          {/* Mobile View */}
          <div className="md:hidden flex items-center">
            <Link to="/">
            <p className="text-[11px] font-poppins font-bold ml-2"> AAOPADHE </p>

            </Link>
          </div>
  
          {/* Desktop Links */}
          <div className="hidden md:block md:ml-10 md:space-x-4 mt-4">
            <Link
              to="/"
              className="text-black hover:bg-pink-500 hover:text-white px-3 py-2 rounded-md text-md font-medium"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-black hover:bg-pink-500 hover:text-white px-3 py-2 rounded-md text-md font-medium"
            >
              About
            </Link>
            <Link
              to="/contact"
              className="text-black hover:bg-pink-500 hover:text-white px-3 py-2 rounded-md text-md font-medium"
            >
              Contact
            </Link>
            <Link
              to="/"
              className="text-black hover:bg-pink-500 hover:text-white px-3 py-2 rounded-md text-md font-medium"
            >
              AaoPadhe
            </Link>
          </div>
        </div>
      </div>
  
      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-4 border-2 pb-3 space-y-1 sm:px-3">
            <Link
              to="/"
              className="text-black hover:bg-pink-600 block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-black hover:bg-pink-600 block px-3 py-2 rounded-md text-base font-medium"
            >
              About
            </Link>
            <Link
              to="/contact"
              className="text-black hover:bg-pink-600 block px-3 py-2 rounded-md text-base font-medium"
            >
              Contact
            </Link>
          </div>
        </div>
      )}
    </div>
  </nav>
  
  );
};

export default Navbar;
